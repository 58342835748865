import React from 'react';

import { FontAwesomeIcon }                 from '@fortawesome/react-fontawesome';
import { faCopy }                          from '@fortawesome/free-solid-svg-icons/faCopy';
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';

import './Modal.css';

import KoFiButton    from "../components/widgets/KoFiButton";
import Switch        from '../components/widgets/LabelSwitch';
import PatreonButton from "../components/widgets/PatreonButton";
import { 
  SEVENTEENLANDS_TIER_LIST_URI_PREFIX,
  SEVENTEENLANDS_TIER_LISTS_URI,
  SEVENTEENLANDS_URI } from '../services/seventeen-lands';

/**
 * The status of the Modal dialog
 */
export class ModalStatus {
  static Closed = new ModalStatus('Closed');
  static About = new ModalStatus('About');
  static Export = new ModalStatus('Export');
  static Paste = new ModalStatus('Paste');

  constructor(status) {
    this.status = status;
  }
  
  /**
   * This checks status equality by internal status name since restoring 
   * status from saved pool state will not restore the same static objects.
   */
  matches(otherStatus) {
    return (this.status === otherStatus.status);
  }

  toString() {
    return `ModalStatus.${this.status}`;
  }
}

/**
 * Base class for a Modal dialog
 */
export default class Modal extends React.Component {

  render() {
    return (
      <div className="modal-background" 
          style={{
            visibility: this.props.visible ? 'visible' : 'hidden'
          }}
          onClick={this.onClickOutsideModal}>
        <div className={this.modalClassNames()}
            style={{
              transform: this.props.visible ? 'translateY(0)' : 'translateY(-100vh)',
              opacity: this.props.visible ? 1 : 0
            }}
            onClick={this.onClickInsideModal}>
            <button className="close"
                title="Close"
                onClick={this.props.onClose}>
              <FontAwesomeIcon icon={farTimesCircle} />
            </button>
            {this.renderChildren()}
        </div>
      </div>
    );
  }

  modalClassNames() {
    return `modal ${this.props.className ?? ""}`;
  }

  renderChildren() {
    return <>{this.props.children}</>;
  }

  onClickInsideModal = (e) => {
    // stop the event from propagating 
    // (prevent triggering onClickOutsideModal)
    e.stopPropagation();
  }

  onClickOutsideModal = () => {
    this.props.onClose();
  }
  
}

/**
 * Modal dialog for pasting/loading a decklist
 */
export class PasteModal extends Modal {

  constructor(props) {
    super(props);
    this.state = {
      pasteContent: "",
      updatePool: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.visible && prevProps.visible) {
      // clear the paste content when toggling visible state
      this.setState({ pasteContent: "" });
    }
  }

  modalClassNames() {
    // add `modal-full-height` class
    return `modal modal-full-height ${this.props.className ?? ""}`;
  }

  renderChildren() {
    return <>
      <h2>Paste Deck</h2>
      <p>
      You can paste your decklist from MTG Arena or MTGO below.
      </p>
      <textarea className="paste"
        value={this.state.pasteContent} 
        onChange={this.handleChange}>
      </textarea>
      <div className='paste-buttons'>
        <button 
            disabled={!this.state.pasteContent.trim()} 
            onClick={this.handlePaste}>
          Load Deck
        </button>
        <Switch
            className="paste-update-switch"
            disabled={this.props.disabled || this.props.disableUpdate} 
            onChange={this.toggleUpdatePool} 
            checked={this.state.updatePool}>
          Update current pool
        </Switch>
      </div>
    </>
  }

  handleChange = (event) => {
    this.setState({ pasteContent: event.target.value });
  }

  handlePaste = (event) => {
    if (this.state.updatePool) {
      this.props.handleUpdate(this.state.pasteContent);
    }
    else {
      this.props.handlePaste(this.state.pasteContent);
    }
  }

  toggleUpdatePool = (e) => this.setState({ updatePool: !this.state.updatePool });

}

/**
 * Modal dialog for exporting an MTG Arena decklist
 */
export class ExportModal extends Modal {

  constructor(props) {
    super(props);
    this.textAreaRef = React.createRef();

    this.state = { 
      exportSet: false,
      exportHidden: false
    };
  }

  componentDidMount() {
    // set the text area content here instead of during render because it 
    // depends on `this.state.exportSet`
    this.resetTextAreaDecklist();
    // auto-select the text area content only when first loaded
    this.selectTextAreaContent();
  }

  componentDidUpdate() {
    // reset the text area content on update, which should only occur 
    // when `this.state.exportSet` is changed
    this.resetTextAreaDecklist();
  }

  modalClassNames() {
    // add `modal-full-height` class
    return `modal modal-full-height ${this.props.className ?? ""}`;
  }

  renderChildren() {
    return <>
      <h2>Export for Arena</h2>
      <p>
      You can copy the decklist below and import it into MTG Arena.
      </p>
      <textarea className="export" 
        ref={this.textAreaRef}>
      </textarea>
      <div className='export-buttons'>
        <button 
            title="Copy to Clipboard"
            onClick={this.copyAndClose} >
          <FontAwesomeIcon icon={faCopy} /> Copy &amp; Close
        </button>
        <Switch
            className="export-set-switch"
            disabled={this.props.disabled} 
            onChange={this.toggleExportSet} 
            checked={this.state.exportSet}>
          Include&nbsp;card&nbsp;set
        </Switch>
        {this.maybeRenderHiddenSwitch()}
      </div>
    </>
  }

  maybeRenderHiddenSwitch() {
    const numHiddenCards = this.props.cardCountsFn(false, false).hidden?.length;
    // Don't render the 'Include Hidden' switch if hidden cards aren't defined 
    // (i.e., a full set cardpool does not have a hidden card section)
    if (numHiddenCards !== undefined) {
      return <>
        <Switch
            className="export-hidden-switch"
            disabled={this.props.disabled || !numHiddenCards}
            onChange={this.toggleExportHidden} 
            checked={this.state.exportHidden}>
          Include&nbsp;{numHiddenCards}&nbsp;hidden
        </Switch>
      </>;
    }
  }

  toggleExportSet = (e) => this.setState({ exportSet: !this.state.exportSet });

  toggleExportHidden = (e) => this.setState({ exportHidden: !this.state.exportHidden });

  copyAndClose = () => {
    this.copyToClipboard(this.textAreaRef.current);
    this.props.onClose();
  }

  copyToClipboard(el) {
    navigator.clipboard.writeText(el.value);
  }

  selectTextAreaContent = () => {
    // attempt to focus the textarea
    this.textAreaRef.current.focus();
    // select the content in reverse, so that the cursor is at the top and does not scroll down
    const length = this.textAreaRef.current.value.length;
    this.textAreaRef.current.setSelectionRange(0, length, "backward");
  }

  resetTextAreaDecklist = () => this.textAreaRef.current.value = this.decklist();

  decklist = () => {
    // optionally distinguish cards by set (Arena also requires `collector_number`)
    const cardCounts = this.props.cardCountsFn(
      /* includeSet = */ this.state.exportSet, 
      /* includeCollectorNo = */ this.state.exportSet,
      /* combineHiddenSideboard = */ this.state.exportHidden);
    const deckCardCounts = cardCounts.deck;
    const deckCount = this.poolCount(deckCardCounts);

    const sideboardCardCounts = cardCounts.sideboard;
    const sideboardCount = this.poolCount(sideboardCardCounts);

    // exit early if pool is empty
    if (deckCount + sideboardCount === 0) {
      return "";
    }
    else {
      let deckList = "";
      // skip Deck entry if empty
      if (deckCount > 0) {
        const deckStr = deckCardCounts.reduce((deck, card) => deck + `${this.lineItem(card)}\n`, "");
        deckList += "Deck\n" + deckStr;
      }
      // skip Sideboard entry if empty
      if (sideboardCount > 0) {
        if (deckList.length) deckList += "\n"
        const sideboardStr = sideboardCardCounts.reduce((sb, card) => sb + `${this.lineItem(card)}\n`, "");
        deckList += "Sideboard\n" + sideboardStr;
      }
      return deckList;
    }
  }

  lineItem = (card) => {
    let item = `${card.count} ${card.name}`;
    if (this.state.exportSet && card.set && card.collector_number) {
      item += ` (${card.set.toUpperCase()}) ${card.collector_number}`;
    }
    return item
  }

  poolCount(poolCounts) {
    return poolCounts ? poolCounts.reduce((sum, card) => sum + card.count, 0) : 0;
  }
}


/**
 * Modal dialog for exporting a tier list to 17Lands
 */
export class ExportTierListModal extends Modal {

  constructor(props) {
    super(props);
    this.state = {
      // if provided, default the update URL to the 17Lands tier list
      tierlistUrl: this.props.seventeenLandsTierListId ?
        `${SEVENTEENLANDS_TIER_LIST_URI_PREFIX}${this.props.seventeenLandsTierListId}` : ''
    };
  }

  renderChildren() {
    return <>
      <h2>Publish and Export to 17Lands</h2>
      <div className="description">
        Are you logged in to your {/* eslint-disable-line */} <a 
          href={SEVENTEENLANDS_URI}
          target="_blank" rel="noopener">
        17Lands.com</a> account?
      </div>
      <div className="export-seventeenlands-create">
        <span>
          <strong>Export a new 17Lands tier list</strong> with the current ratings:
        </span>
        <button title="Create a new 17Lands tier list"
            onClick={this.handleExportNew}>
          New 17Lands Tier List
        </button>
      </div>
      <div className="export-seventeenlands-update">
        <span>
          <strong>Update an {/* eslint-disable-line */} <a
              href={SEVENTEENLANDS_TIER_LISTS_URI}
              target="_blank" rel="noopener">
                existing 17Lands tier list</a>
          </strong> by pasting its URL below:
        </span>
        <input placeholder={`${SEVENTEENLANDS_TIER_LIST_URI_PREFIX}...`}
          onClick={this.handleFocus}
          value={this.state.tierlistUrl} 
          onChange={this.handleChange} />
        <button title="Update an existing 17Lands tier list"
            disabled={!this.state.tierlistUrl.trim()} 
            onClick={this.handleExportUpdate}>
          Update 17Lands Tier List
        </button>
      </div>
    </>
  }

  handleFocus = (event) => event.target.select();

  handleChange = (event) => {
    this.setState({ tierlistUrl: event.target.value });
  }

  handleExportNew = (event) => {
    this.props.handleExport();
    this.props.onClose();
  }

  handleExportUpdate = (event) => {
    const url = this.state.tierlistUrl.trim();

    // extract the 17Lands tier list ID
    let tier_list_id = undefined;
    if (url.startsWith(SEVENTEENLANDS_TIER_LIST_URI_PREFIX)) {
      tier_list_id = url.slice(SEVENTEENLANDS_TIER_LIST_URI_PREFIX.length);
    }
    
    this.props.handleExport(tier_list_id);
    this.props.onClose();
  }
}

/**
 * Modal dialog for "About" information
 */
 export class AboutModal extends Modal {

  renderChildren() {
    return <>
      <h2>About SealedDeck.Tech</h2>
      <p>
        My name is Dave and I am building <strong>SealedDeck.Tech</strong>. 
      </p>
      <p>
        You can 
        {/* eslint-disable-line */} <a 
          href="https://patreon.com/sealeddecktech"
          target="_blank" rel="noopener">
        <strong>support the site on Patreon</strong></a> for perks like 
        voting on new features and early access to features under development. Or 
        {/* eslint-disable-line */} <a 
          href="https://ko-fi.com/sealeddecktech"
          target="_blank" rel="noopener">
        <strong>say thanks with a tip on Ko-fi</strong></a> toward my next coffee (or draft entry).
      </p>
      <div className="support">
        <PatreonButton /> <KoFiButton />
      </div>
      <p><strong>SealedDeck.Tech</strong> is built using 
        {/* eslint-disable-line */} <a 
          href="https://reactjs.org"
          target="_blank" rel="noopener">
        ReactJS</a>, 
        {/* eslint-disable-line */} <a 
          href="https://github.com/hello-pangea/dnd#readme"
          target="_blank" rel="noopener">
        @hello-pangea/dnd</a>, and 
        {/* eslint-disable-line */} <a 
          href="https://github.com/parse-community/parse-server#readme"
          target="_blank" rel="noopener">
        Parse Server</a>. Card images and data come from 
        {/* eslint-disable-line */} <a 
          href="https://scryfall.com/" 
          target="_blank" rel="noopener">
        Scryfall</a>. Games in Hand Win Rate (GIH WR) statistics come from 
        {/* eslint-disable-line */} <a 
          href="https://17lands.com/" 
          target="_blank" rel="noopener">
        17Lands</a>.  
      </p>
      <p>
        Icons are from the
        {/* eslint-disable-line */} <a 
          href="https://mana.andrewgioia.com" 
          target="_blank" rel="noopener">
        Mana</a> and 
        {/* eslint-disable-line */} <a 
          href="https://keyrune.andrewgioia.com" 
          target="_blank" rel="noopener">
        Keyrune </a> fonts by 
        {/* eslint-disable-line */} <a 
        href="https://andrewgioia.com" 
        target="_blank" rel="noopener">
          Andrew Gioia</a>, and from
        {/* eslint-disable-line */} <a 
          href="https://fontawesome.com"
          target="_blank" rel="noopener">
        Font Awesome</a>. The font used for headings is the 
        {/* eslint-disable-line */} <a 
          href="https://github.com/Saeris/typeface-beleren-bold#readme"
          target="_blank" rel="noopener">
        Beleren Bold typeface</a>.
      </p>
      <p className="wotc-fan-content">
        SealedDeck.Tech is unofficial Fan Content permitted under the 
        {/* eslint-disable-line */} <a 
          href="https://company.wizards.com/en/legal/fancontentpolicy"
          target="_blank" rel="noopener">
        Fan Content Policy</a>. Not approved/endorsed by Wizards. 
        Portions of the materials used are property of Wizards of the Coast. 
        ©Wizards of the Coast LLC.
      </p>
      <div className='about-buttons'>
        <button onClick={this.props.onClose}>Close</button>
      </div>
    </>
  }
}
